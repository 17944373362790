import request from '@/service/request'

/* 羽毛球场地黑名单，分页
* 接口地址准确 因为后端颠颠的，乱来 ，导致羽毛球几个page接口命名不规范
* @params
* */
export const pageBadmintonBlockListApi = (params) => {
  return request.post('/stu/badmintonBlackUser/page', params)
}

/* 羽毛球场地黑名单 移除黑名单
* @params id
* */
export const unBlockUserBadmintonApi = (id) => {
  return request.post(`/stu/badmintonBlackUser/remove/${id}`)
}
