<!--  场地黑名单  -->
<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable">
    <template #header>
      <view-search-form
        ref="searchFormRef"
        :queryInfo.sync="queryInfo"
        :tool-list="['keyword']"
        @on-search="renderTable(1)">
        <el-form-item label="黑名单在列" label-width="100px">
          <el-select
            v-model="queryInfo.passFlag"
            clearable
            size="small">
            <el-option
              v-for="{value,label} in passFlagList"
              :key="value"
              :label="label"
              :value="value" />
          </el-select>
        </el-form-item>
        <el-form-item label="用户类型">
          <el-select
            v-model="queryInfo.userType"
            clearable
            size="small">
            <el-option :value="2" label="学生" />
            <el-option :value="3" label="教职工" />
          </el-select>
        </el-form-item>
      </view-search-form>
    </template>
    <el-table :data="tableData" style="width: 100%;" border stripe>
      <el-table-column prop="stuName" label="姓名" width="220">
        <template v-slot="{row}">
          {{ row.stuName || row.teaName }}
        </template>
      </el-table-column>
      <el-table-column prop="stuName" label="用户类型" min-width="88" align="center">
        <template v-slot="{row}">
          <el-tag v-if="row.userType === 2">学生</el-tag>
          <el-tag v-if="row.userType === 3" type="success">教职工</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="联系电话" width="160">
        <template v-slot="{row}">
          {{ row.fixedPhone || row.teaPhone }}
        </template>
      </el-table-column>
      <el-table-column label="部门/专业班级" min-width="260">
        <template v-slot="{row}">
          <template v-if="row.className">
            {{row.campusName}}{{row.majorName}}{{row.className}}
          </template>
          <template v-else>
            {{row.teaDeptName||row.teaCollegeName}}
          </template>
        </template>
      </el-table-column>
      <el-table-column label="备注" prop="remarks" min-width="200"/>
      <el-table-column label="操作" width="130" align="center" fixed="right">
        <template v-slot="{row}">
          <template v-if="row.passFlag">
            <span class="font-grey">--已移出黑名单--</span>
          </template>
          <template v-else>
            <el-button v-permission="['badminton:block']"
                       size="mini"
                       type="danger"
                       @click="unBlock(row)"
            >移出黑名单
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
  </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import { throttle } from 'lodash'
import { pageBadmintonBlockListApi, unBlockUserBadmintonApi } from '@/api/student-manage/badminton-block-list-api'

export default {
  name: 'badmintonBlockList',
  mixins: [tableView],
  data() {
    return {
      queryInfo: {
        passFlag: 0, // 黑名单状态
        userType: null
      },
      // 黑名单状态
      passFlagList: [
        {
          value: 0,
          label: '是'
        },
        {
          value: 1,
          label: '否'
        }
      ]
    }
  },
  methods: {
    async renderTable(pageNum) {
      await this.getTableData(pageBadmintonBlockListApi, pageNum)
    },
    unBlock: throttle(function(row) {
      this.$confirm(`<p class="confirm-message">此操作将把<b class="warning"> ${row.stuName}</b>移出球场黑名单，是否继续?</p>`, '移出黑名单', {
        type: 'warning',
        dangerouslyUseHTMLString: true
      })
        .then(async () => {
          this.loading = true
          try {
            await unBlockUserBadmintonApi(row.id)
            this.$message.success(`${row.stuName}已移出黑名单!`)
            await this.renderTable()
          } catch (e) {
          } finally {
            this.loading = false
          }
        })
        .catch(() => {
          this.$message('已取消')
        })
    }, 1000, {})
  }
}
</script>
